<template>
  <default-layout>
    <v-container fluid tag="section">
      <page-loading :show="loading" />
      <v-alert type="error" :value="showAlert" dismissible>
        <div v-html="messageAlert" />
      </v-alert>
      <v-row>
        <v-col cols="6">
          <div class="d-flex flex-wrap">
            <detail
              :detail="detail"
              :permissions="permissions"
              :allowedActions="allowedActions"
              style="width: 100%"
            ></detail>
          </div>
        </v-col>
        <!--        <v-col cols="6">-->
        <!--          <div class="d-flex flex-wrap">-->
        <!--            <assigned-agent-detail-->
        <!--              :detail="detail"-->
        <!--              :permissions="permissions"-->
        <!--              :allowedActions="allowedActions"-->
        <!--              style="width: 100%"-->
        <!--            ></assigned-agent-detail>-->
        <!--          </div>-->
        <!--        </v-col>-->
      </v-row>
    </v-container>
    <modal-withdrawal></modal-withdrawal>
  </default-layout>
</template>

<script>
import DefaultLayout from '@/layouts/default';
const PageLoading = () => import('@/components/content-loading/page-loading.vue');
const Detail = () => import('@/components/commission-management/withdrawal-request/detail');
const ModalWithdrawal = () => import('@/components/modals/modal-withdrawal');
import { mapState } from 'vuex';

export default {
  components: {
    DefaultLayout,
    PageLoading,
    Detail,
    ModalWithdrawal,
  },
  data() {
    return {
      showAlert: false,
      messageAlert: null,
    };
  },
  computed: {
    ...mapState({
      loading: (state) => state.withdrawalRequest.loading,
      permissions: (state) => state.withdrawalRequest.permissions,
      allowedActions: (state) => state.withdrawalRequest.allowedActions,
      detail: (state) => state.withdrawalRequest.detail,
    }),
  },
  async mounted() {
    await this.loadDetail();
  },
  methods: {
    async refreshAction() {
      await this.loadDetail();
    },
    async loadDetail() {
      try {
        this.$store.commit('withdrawalRequest/RESET_DEFAULT');
        const response = await this.$store.dispatch(
          'withdrawalRequest/getDetailInitData',
          this.$route.params.id,
        );
        console.log('DETAIL LEAD: ', response);
      } catch (e) {
        this.showAlert = true;
        this.messageAlert = e;
      }
    },
  },
};
</script>
